import { CurrentProfileApiResponse } from 'api/api.types';
import { TOASTS_CONTROLLER } from 'hooks/toast/useToastsController';
import Cookie from 'js-cookie';
import {
  COOKIE_ANALYTICS_TOAST_ENABLED,
  WEB_FUNNEL_CAMPAIGNS_FOR_EXPERIMENT,
} from 'utils/constants';
import { subscriptionTypeToString } from 'utils/global';
import * as amplitude from '@amplitude/analytics-browser';
import {
  Experiment,
  ExperimentClient,
  Variants,
} from '@amplitude/experiment-js-client';

class Analytics {
  private static instance: Analytics;

  private static amplitudeInstance: amplitude.Types.BrowserClient;
  private static isInitiated = false;

  private static experimentInstance: ExperimentClient;
  private static isExperimentInitiated = false;

  private static experiments: Record<string, string> | undefined = undefined;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static getInstance = (): Analytics => {
    if (!Analytics.instance) {
      Analytics.instance = new Analytics();
    }
    return Analytics.instance;
  };

  public getExperiments = () => {
    return Analytics.experiments;
  };

  public getExperiment(key: string) {
    return Analytics.experiments?.[key];
  }

  /**
   * Init Amplitude Instance, should be called once on app start
   */
  public initAmplitude = () => {
    if (process.env.NEXT_PUBLIC_LIGHTHOUSE_ENV || Analytics.isInitiated) {
      return;
    }

    const createdAmplitudeInstance = amplitude.createInstance();
    createdAmplitudeInstance.init(process.env.AMPLITUDE_API_KEY_MOBILE ?? '', {
      autocapture: false,
    });
    Analytics.amplitudeInstance = createdAmplitudeInstance;

    Analytics.isInitiated = true;

    // Set user properties: web-version & web_first_version
    const identify = new amplitude.Identify();

    identify.set('web-version', process.env.WEBSITE_VERSION ?? '');
    identify.setOnce('web_first_version', process.env.WEBSITE_VERSION ?? '0.0');
    Analytics.amplitudeInstance.identify(identify);
    console.log('[Amplitude] setUserProperties');

    console.log('[Amplitude] initAmplitude');
  };

  public initializeExperiments = async () => {
    if (Analytics.isExperimentInitiated) {
      return this.getExperiments();
    }

    const experiment = Experiment.initializeWithAmplitudeAnalytics(
      process.env.AMPLITUDE_EXPERIMENTS_DEPLOYMENT_KEY ?? '',
      {
        debug: true,
      },
    );

    return experiment
      .start()
      .then(() => {
        Analytics.experimentInstance = experiment;
        Analytics.isExperimentInitiated = true;

        const all = experiment.all();
        // console.log(
        //   `[Amplitude Experiment] Started with ${JSON.stringify(all)}`,
        // );
        return this.setExperimentsAsUserProperties({ variants: all });
      })
      .catch(() => {
        console.log(`[Amplitude Experiment] Failed to start`);
      });
  };

  public setExperimentsAsUserProperties = ({
    variants,
  }: {
    variants: Variants;
  }) => {
    const identify = new amplitude.Identify();

    const experimentsRecord: Record<string, string> = {};

    Object.keys(variants).forEach(key => {
      identify.setOnce(
        `prop_web_exp_${key}`,
        `${variants[key].key ?? 'not-determined'}`,
      );

      experimentsRecord[`${key}`] = `${variants[key].key ?? 'not-determined'}`;
    });

    Analytics.amplitudeInstance.identify(identify);
    Analytics.experiments = experimentsRecord;
    return experimentsRecord;
  };

  /** Can be used to get experiment variants */
  public getExperimentInstance = () => {
    return Analytics.experimentInstance;
  };

  public setUserEmail = ({ email }: { email: string }) => {
    this.initAmplitude();

    Analytics.amplitudeInstance?.setUserId(email?.toLowerCase());
    console.log('[Amplitude] setUserEmailAsId');
  };

  public setUserIdAndProperties = ({
    profile,
  }: {
    profile: CurrentProfileApiResponse;
  }) => {
    this.initAmplitude();

    // Set user's id and properties if logged-in
    this.setUserId({ userId: profile?.email?.toLowerCase() });
    this.setUserProperties({
      created_at: profile.created_at,
      insight_count: profile.insight_count,
      stashes_count: profile.stashes_count,
      current_streak: profile.current_streak,
      longest_streak: profile.longest_streak,
      daily_goal: profile.daily_reading_goal,
      following_count: (profile.following ?? []).length,
      followers_count: (profile.followers ?? []).length,
      twitter_username: profile.twitter_username ?? '',
      followed_hashtags: profile.followed_hashtags ?? [],
      payment_plan: subscriptionTypeToString({
        subscriptionType: profile.subscription_type,
      }),
    });

    // User Properties that will NOT change, using setOnce
    const identify = new amplitude.Identify();
    identify.setOnce('web_first_version', process.env.WEBSITE_VERSION ?? '0.0');
    Analytics.amplitudeInstance.identify(identify);
  };

  /**
   * Set user's id, should be called on user login
   */
  private setUserId = ({ userId }: { userId: string }) => {
    Analytics.amplitudeInstance?.setUserId(userId);
    console.log('[Amplitude] setUserId');
  };

  /** Set user's properties as object, should be called after user has an id */
  private setUserProperties = (
    userProperties: Partial<CurrentProfileApiResponse> & Record<string, any>,
  ) => {
    const identify = new amplitude.Identify();
    Object.keys(userProperties).forEach(key => {
      identify.set(key, userProperties[key]);
    });
    identify.set('web-version', process.env.WEBSITE_VERSION ?? '');
    Analytics.amplitudeInstance.identify(identify);
    console.log('[Amplitude] setUserProperties');
  };

  /** Called on logout */
  public setAnonymousUser = () => {
    this.initAmplitude();

    Analytics.amplitudeInstance?.reset();
    console.log('[Amplitude] setAnonymousUser');
  };

  /** Called when setting a user prop */
  public setIdentifyUserProp = ({
    propName,
    propValue,
  }: {
    propName: string;
    propValue: string;
  }) => {
    this.initAmplitude();

    const identify = new amplitude.Identify();
    identify.set(propName, propValue);
    Analytics.amplitudeInstance.identify(identify);
    console.log('[Amplitude] setIdentifyProp');
  };

  /** Log an event */
  public logEvent = ({
    eventName,
    properties,
    forceSendEvent,
    platforms,
  }: {
    eventName: string;
    properties?: Record<string, any>;
    /** Send the event even if not on utm_campaign */
    forceSendEvent?: boolean;
    platforms: ('amplitude' | 'amplitude-mobile' | 'firebase')[];
  }) => {
    const utmCampaign = Cookie.get('utm_campaign');

    const shouldSendEvent = WEB_FUNNEL_CAMPAIGNS_FOR_EXPERIMENT.includes(
      utmCampaign ?? '',
    );

    if (!shouldSendEvent && !forceSendEvent) {
      return;
    }

    this.initAmplitude();

    platforms.forEach(platform => {
      switch (platform) {
        // This is beacause of depracted Amplitude instance of Web
        // We only keep some events that are sent to the mobile Ampliutde instance
        case 'amplitude-mobile': {
          Analytics.amplitudeInstance?.logEvent(eventName, {
            ...properties,
            ...Analytics.experiments,
          });
          break;
        }
        default:
          break;
      }
    });

    const cookieAnalyticsToastEnabled = Cookie.get(
      COOKIE_ANALYTICS_TOAST_ENABLED,
    );
    const isAnalyticsToastEnabled = cookieAnalyticsToastEnabled
      ? cookieAnalyticsToastEnabled.toLowerCase() == 'true'
      : false;

    if (isAnalyticsToastEnabled) {
      TOASTS_CONTROLLER.showToast?.({
        title: eventName,
        description: JSON.stringify(properties) || '',
      });
    }

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log(
        `[Analytics] ${eventName} \n Props: ${JSON.stringify(
          properties,
        )} \n Platforms={${platforms}}`,
      );
    }
  };
}

export default Analytics.getInstance();
